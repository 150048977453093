import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['prefecture', 'municipality']

  async handlePrefectureChange() {
    const response = await fetch(`/municipalities_by_prefecture?prefecture=${this.prefectureTarget.value}`);
    const municipalities = await response.json();

    this.municipalityTarget.innerHTML = '';
    if(Object.keys(municipalities).length > 1){
      this.addBlankOption(this.municipalityTarget, municipalities);
    }

    municipalities.forEach((municipality) => {
      const option = document.createElement('option');
      option.value = municipality.id;
      option.text = municipality.municipality_name;
      this.municipalityTarget.appendChild(option);
    });
  }

  addBlankOption(target) {
    const option = document.createElement('option');
    option.value = '';
    option.text = '選択してください';
    target.appendChild(option);
  }
}
