import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['prefecture', 'line', 'station']

  async handlePrefectureChange() {
    const response = await fetch(`/lines_by_prefecture?prefecture=${this.prefectureTarget.value}`);
    const lines = await response.json();

    this.lineTarget.innerHTML = '';
    this.stationTarget.innerHTML = '';
    if(Object.keys(lines).length > 1){
      this.addBlankOption(this.lineTarget);
    }

    lines.forEach((line) => {
      const option = document.createElement('option');
      option.value = line.id;
      option.text = line.common_line_name;
      this.lineTarget.appendChild(option);
    });
  }

  async handleLineChange() {
    const response = await fetch(`/stations_by_line?prefecture=${this.prefectureTarget.value}&line=${this.lineTarget.value}`);
    const stations = await response.json();

    this.stationTarget.innerHTML = '';
    if(Object.keys(stations).length > 1){
      this.addBlankOption(this.stationTarget);
    }

    stations.forEach((station) => {
      const option = document.createElement('option');
      option.value = station.id;
      option.text = station.station_name;
      this.stationTarget.appendChild(option);
    });
  }

  addBlankOption(target) {
    const option = document.createElement('option');
    option.value = '';
    option.text = '選択してください';
    target.appendChild(option);
  }
}
