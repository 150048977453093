import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "lastName",
    "firstName",
    "lastNameKana",
    "firstNameKana",
    "phoneNumber",
    "email",
    "dateOfBirth",
    "submit",
  ]

  connect() {
    this.checkForm()
  }

  checkForm() {
    const allFilled = this.lastNameTarget.value !== '' &&
      this.firstNameTarget.value !== '' &&
      this.lastNameKanaTarget.value !== '' &&
      this.firstNameKanaTarget.value !== '' &&
      this.phoneNumberTarget.value !== '' &&
      this.emailTarget.value !== '' &&
      this.dateOfBirthTarget.value !== ''

    this.submitTarget.disabled = !allFilled

    if (allFilled) {
      this.submitTarget.classList.add('btn-primary')
    } else {
      this.submitTarget.classList.remove('btn-primary')
    }
  }
}
