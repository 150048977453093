import { Controller } from 'stimulus'

/*
例:<tr>にリンク要素を追加する
<tr
  data-controller="href"
  data-action="click->href#toHref keydown->href#enterToHref"
  data-href="<%= sample_path %>"
  tabindex="0"
  role="link"
  style="cursor: pointer;">
*/
export default class extends Controller {
   // 親要素のdata-href属性にあるパスに遷移する
  toHref(event) {
    event.preventDefault()
    const href = event.currentTarget.dataset['href']
    window.location.href = href
  }

  // フォーカスした要素上でエンターを押した時、遷移する
  enterToHref(event) {
    if (event.target !== event.currentTarget) return

    if (event.key === 'Enter') {
      this.toHref(event)
    }
  }
}
